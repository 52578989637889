.font-12 {
	font-size: 12px !important;
}

.font-14 {
	font-size: 14px !important;
}

.font-16 {
	font-size: 16px !important;
}

.font-20 {
	font-size: 20px !important;
}

#main-logo {
	max-width: 100px;
	/*height: calc(40vh - 175px);*/
}

.pcoded-main-container {
	margin-left: 295px;
}

.pcoded-navbar {
	width: 295px;
	top: 15px;
}

.pcoded-navbar.navbar-collapsed:hover {
	width: 295px !important;
}

[class*="_TableHeadRow"] {
	background: #ecf0f5 !important;
	margin: 6px;
	padding: 15px 0;
}

[class*="_TableHeadRow"] [class*="_TableCol"] {
	font-size: 14px;
	color: black;
	text-transform: uppercase;
}

[class*="_TableBody"] div[role = "row"] {
	box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.30);
	border-radius: 0.25rem;
	position: relative;
	margin: 6px;
	padding: 15px 0;
	width: calc(100% - 12px);
}

[class*="_TableBody"] div[role = "row"]:hover {
	box-shadow: 0 0 6px 0 #4680ff;
	/*background: #4680ff;*/
	/*color: white;*/
}

/*[class*="_TableBody"] div[role = "row"]:hover h6{*/
/*    color: white;*/
/*}*/

[class*="_TableBody"] .overlay-edit {
	position: absolute;
	opacity: 0;
	top: 0;
	right: 0;
	background: #4680ff;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

[class*="_TableBody"] div[role = "row"]:hover .overlay-edit {
	opacity: 1;
}

/* Dx g table  */
.dx-g-bs4-fixed-cell {
	background: #ecf0f5 !important;
}

.user-profile-list .accomplishment table thead {
	border-radius: 0.25rem;
}

.user-profile-list .accomplishment table tbody tr:nth-child(2n) td {
	background-color: #fafafa;
}

.user-profile-list .accomplishment table tbody tr:hover {
	box-shadow: 0 0 6px 0 rgb(70 128 255) !important;
}

.user-profile-list .accomplishment table thead th {
	white-space: break-spaces !important;
	text-align: center;
}

.user-profile-list .accomplishment table tbody tr td {
	white-space: normal !important;
}

.user-profile-list .accomplishment table tbody tr:hover td {
	cursor: pointer;
	color: #000;
	background: #fff;
}

/*.navbar-wrapper ul.nav > li:last-child{*/
/*    display: none;*/
/*}*/
.cover-image-block {
	border: 5px solid white;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.user-card .change-profile .profile-dp.user-dp {
	width: 80px;
	height: 80px;
}

.user-card .change-profile .profile-dp.user-dp img {
	height: 68px;
	width: 68px;
	object-fit: cover;
}

.studio-profile-img {
	width: 80px;
	height: 80px;
	border-radius: 80px;
	object-fit: contain;
	background: grey;
	/*margin-right: 10px;*/
}

.cover-img-block {
	background: grey;
}

.cover-img-block img {
	width: 100%;
	height: 230px;
	object-fit: contain;
}

.tbl-icons {
	font-size: 22px;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
}

.modal-main {
	position: fixed;
	background: white;
	width: 50%;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.display-block {
	display: block;
}

.display-none {
	display: none;
}

/*maps*/
.pac-container {
	z-index: 9999 !important;
}

.select-style {
	width: 100%;
}

.cursor-pointer {
	cursor: pointer;
}

.badge {
	white-space: normal;
}

.swal2-icon.swal2-question,
.swal2-icon.swal2-warning {
	border-color: red !important;
	color: red !important;
}

/*.swal2-styled.swal2-confirm {*/
/*    background: red !important;*/
/*}*/
.swal2-styled.swal2-confirm:focus {
	box-shadow: none;
}

.react-datepicker-popper {
	z-index: 3;
}

.custom-control-input-mine {
	position: absolute;
	left: calc(50% - 1rem);
	z-index: -1;
	width: 2rem;
	height: 2rem;
	opacity: 0;
	top: -5px;
}

.hBLBhZ div:first-child {
	text-overflow: inherit !important;
	white-space: normal !important;
}

[class*="_TableBody"] div[role = "row"] {
	box-sizing: content-box;
}

.btn-primary {
	color: #fff;
	background-color: #bf40bf;
	border-color: #bf40bf;
}

.btn-primary:hover {
	color: #fff;
	background-color: #800080;
	border-color: #800080;
}

.css-1pahdxg-control:hover,
.css-1pahdxg-control:active {
	border-color: #bf40bf !important;
	box-shadow: none !important;
}

a.text-primary:hover,
a.text-primary:focus {
	color: #000000 !important;
}

.icon-position {
	position: relative;
	top: 5px;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #bf40bf;
	border-color: #bf40bf;
}

.btn-primary:focus,
.btn-primary.focus {
	color: #fff;
	background-color: #bf40bf;
	border-color: #bf40bf;
}

.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	border-color: #bf40bf;
	background-color: #bf40bf;
}

.preview-img {
	max-width: 160px;
	padding: 5px;
	border: 1px solid lightgrey;
	border-radius: 5px;
	margin: 0 auto;
}

.preview-img img {
	max-width: 150px;
}

.preview-img .img-close {
	padding: 7px;
	/* background: #F07631; */
	font-weight: 700;
	border: 1px solid #bf40bf;
	display: inline-block;
	position: absolute;
	height: 30px;
	width: 30px;
	text-align: center;
	line-height: 1;
	border-radius: 30px;
	right: 5px;
}

.equ-input {
	width: 5%;
	text-align: center;
}

.text-underline {
	text-decoration: underline;
}

.custom-hr {
	margin-top: 2rem;
	border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.reconnect-text {
	color: #bf40bf;
	font-size: 12px;
}

.sort-text {
	right: 15px;
	top: 41px;
	color: #bf40bf;
	font-size: 12px;
	position: absolute;
}
.msg-sort-text {
	right: 15px;
	top: 0px;
	color: #bf40bf;
	font-size: 12px;
	position: absolute;
}
.message-modal-input {
	width: 15%;
	text-align: center;
}

.assignment-radio [type="radio"]:checked,
.assignment-radio [type="radio"]:not(:checked) {
	position: absolute;
	left: -9999px;
}
.assignment-radio [type="radio"]:checked + label,
.assignment-radio [type="radio"]:not(:checked) + label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
	color: #666;
}
.assignment-radio [type="radio"]:checked + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 25px;
	height: 25px;
	border: 2px solid #4680ff;
	border-radius: 100%;
	background: #fff;
}
.assignment-radio [type="radio"]:not(:checked) + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 25px;
	height: 25px;
	border: 2px solid #bf40bf;
	border-radius: 100%;
	background: #fff;
}
.assignment-radio [type="radio"]:checked + label:after {
	content: '';
	width: 15px;
	height: 15px;
	background: #4680ff;
	position: absolute;
	top: 5px;
	left: 5px;
	border: 2px solid #4680ff;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
.assignment-radio [type="radio"]:not(:checked) + label:after {
	content: '';
	width: 15px;
	height: 15px;
	background: #bf40bf;
	position: absolute;
	top: 5px;
	left: 5px;
	border: 2px solid #bf40bf;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
.assignment-radio [type="radio"]:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}
.assignment-radio [type="radio"]:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}
.scroll-v {
	overflow-y: scroll;
	max-height: 400px;
}
.input-group-prepend .btn,
.input-group-append .btn {
	position: relative;
	z-index: 0;
}
.topic {
	cursor: pointer;
	font-weight: bold;
}
.topic:hover {
	color: #bf40bf;
}
.participants-th .kibde {
	max-height: 460px;
}
.primary-color {
	color: #bf40bf;
}
.white-space {
	white-space: pre-line;
}
/*.form-control {*/
/*    border: 1px solid #cccccc;*/
/*}*/
/*.form-group .form-control {*/
/*    padding: 10px;*/
/*}*/

.edit-color-box {
	font-size: 90%;
}

.edit-color-box #color-box-title {
	font-size: 110%;
	color: yellow;
}
.swal2-container {
	z-index: 1073 !important;
}
.calories-title-bg{
	background-color: #DFDFDF;
}
.calories-data-bg{
	background-color: #E5E5E5;
}
.apexcharts-canvas {
	z-index: 0;
}
.react-datepicker {
	z-index: 2;
}

.participant-modal {
	border: 1px solid #ced4da;
	padding: 10px;
	border-radius: 5px;
	width: calc(100% - 217px);
	margin-left: 15px;
}

/* Chrome, Safari, Edge, Opera */
.otp input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.otp input[type=number] {
	-moz-appearance: textfield;
}